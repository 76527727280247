<template>
  <div>
    <ListingLayout
      v-model="selectedRows"
      :is-loading="isLoading"
      :meta="meta"
      :headers="headers"
      :items="list"
      :multi-select-rows="showHeaders"
      no-filters
      @filterOption="onFiltersChange"
    >
      <template v-slot:flespi_plugin_id="{ item }">
        <div class="open-link">
          <!-- @click="redirectIOTScreen(item)" -->
          <router-link :to="redirectIOTScreen(item)">
            {{ item.flespi_plugin_id }}
          </router-link>
        </div>
      </template>
      <template v-slot:tenant_names="{ item }">
        <div>
          {{ item.tenant_names?.join(", ") }}
        </div>
      </template>
      <template v-slot:url="{ item }">
        <div class="relative copy-content">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  class="copy-content-icon"
                  small
                  @click="clickToCopy"
                >mdi mdi-content-copy
                </v-icon>
              </span>
            </template>
            <span>{{ item.url }}</span>
          </v-tooltip>

          <v-text-field
            ref="myText"
            :value="item.url"
            readonly
            style="width: 1px; height: 1px"
          />
        </div>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters> </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div
          v-if="showHeaders"
          class="d-flex"
          style="gap: 0.5rem"
        >
          <bulk-assign
            v-if="selectedRows.length > 0"
            @apply="onApply"
          />
          <v-btn
            v-if="$admin.can('flespi-plugins.sync')"
            color="primary"
            dark
            :loading="isLoading"
            @click="syncData"
          >
            {{ $t("sync") }}
          </v-btn>
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    BulkAssign: () => import("./utils/BulkAssign.vue"),
  },
  mixins: [ListingLayoutMixins],
  props: {
    showHeaders: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      filters: {},
      subFilters: {},
      //
      selectedRows: [],
      //
      headers: [
        {
          text: this.$t("flespi_plugin_id"),
          align: "left",
          sortable: true,
          value: "flespi_plugin_id",
          width: "10%",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
          //   width: "20%",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
          width: "20%",
          //   showAdmin: true,
        },
        {
          text: this.$t("assigned_tenant"),
          align: "left",
          sortable: false,
          value: "tenant_names",
          width: "25%",
          //   showAdmin: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "flespiPlugin/getIsLoading",
      meta: "flespiPlugin/getMeta",
      list: "flespiPlugin/getPluginsList",
    }),
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "plugins",
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      // if (!this.list.length > 0) {
      await this.$store.dispatch("flespiPlugin/list", this.options);
      // }
    },
    async syncData() {
      await this.$store.dispatch("flespiPlugin/pluginSync", this.options);
    },
    redirectIOTScreen(row) {
      return {
        path: "/iot-devices",
        query: { flespi_plugin_id: row.flespi_plugin_id },
      };
      // this.$router.push({
      //   path: "/iot-devices",
      //   query: { flespi_plugin_id: row.flespi_plugin_id },
      // });
    },
    //
    //
    async onApply(v) {
      const params = {
        action: v.value,
        tenant_ids: v.tenant_ids,
        flespi_plugin_ids: this.selectedRows.map((r) => r.flespi_plugin_id),
      };
      await this.$store
        .dispatch("flespiPlugin/bulkAssign", params)
        .then((res) => {
          this.$store.dispatch("alerts/success", res.message);
          this.fetchData();
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", err?.response?.data?.message);
        });
    },
  },
};
</script>
<style lang="sass" scoped>
.copy-content
  overflow: hidden !important
.copy-content-icon
  cursor: pointer
  position: absolute
  display: flex
  justify-content: center
  width: 100%
  align-items: center
  height: 100%
  &:hover
    color: #20a390  !important
</style>
